import { base as preset } from "@theme-ui/presets"
import { nominalTypeHack } from "prop-types"

// example theme file
export default {
  ...preset,
  breakpoints: ["768px", "1024px", "2048px"],
  fonts: {
    ...preset.fonts,
    article: "Literata, Georgia, serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 36, 48, 64, 96],
  colors: {
    ...preset.colors,
    text: "#222",
    textDim: "#aaa",
    primary: "hsl(242, 82%, 72%)",
    linkHighlight: "hsl(242, 82%, 88%)",
  },
  // lineHeights: {
  //   body: 1.625,
  //   heading: 1.25,
  // },
  text: {
    article: {
      //fontFamily: "article",
      fontSize: 2,
      lineHeight: 1.6,
    },
  },
  sizes: {
    container: "672",
    text: "32em",
  },
  styles: {
    ...preset.styles,

    root: {
      ...preset.styles.root,
      textRendering: "optimizeLegibility",
      // "-moz-osx-font-smoothing": "grayscale",
      // overflow: "hidden", TODO: gatsbyjs.com toggles overflow-y on <html> element with react-helmet to prevent scrollign with full-screen modal
      // fontSize: [".875rem", "1rem"], // could use this to scale down on mobile
    },
    h1: {
      ...preset.styles.h1,
      fontSize: 4,
      fontWeight: 500,
    },
    h2: {
      ...preset.styles.h2,
      fontSize: 3,
      fontWeight: 500,
    },
    h3: {
      ...preset.styles.h3,
      fontSize: 3,
      fontWeight: 400,
      // mt: 5,
    },
    // p: {
    //   fontSize: 3,
    // },
    // li: {
    //   fontSize: 3,
    // },
    a: {
      color: "text",
      transition: "all 0.2s",
      textDecoration: "none",
      borderBottom: "2px solid",
      borderColor: "linkHighlight",
      ":hover": {
        // backgroundColor: "linkHighlight",
        borderColor: "primary",
      },
    },
    blockquote: {
      fontStyle: "italic",
      fontWeight: 300,
      mx: 0,
      my: 4,
      p: {
        fontSize: "inherit",
        fontFamily: "body",
      },
      footer: {
        fontSize: 3,
        textAlign: "right",
      },
    },
    p: {
      variant: "text.article",
      my: "1.6em",
    },
    ul: {
      variant: "text.article",
      my: "1.625em",
      // wordWrap: "break-word",
      "& > ul": {
        my: 0,
      },
    },
    ol: {
      my: "1.6em",
    },
    hr: {
      border: "none",
      borderTop: "1px solid #ddd",
    },
    strong: {
      fontWeight: 500,
    },
  },
}
